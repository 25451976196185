import React, { useState } from "react";
import Navbar from "../Misc/Navbar";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { Link } from "react-router-dom";
import signup from "../../Assets/signup.png";
// import signupvideo from "D:/TMaaS/Frontend/src/Assets/signupvideo.mp4"
import { TextField } from "@mui/material";

export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleInputChange_name = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(value)) {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validatePassword(formData.password)) {
      setErrors({ password: "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character." });
      return;
    }

    // Hash the password before sending it to the server
    const hashedPassword = await bcrypt.hash(formData.password, 10);

    const loginData = {
      name: formData.name,
      email: formData.email,
      password: hashedPassword, // Use the hashed password
    };

    axios
      .post("https://tmaasbackend.tdtlworld.com/Signup_view/", loginData)
      .then((response) => {
        console.log("SignUp Successful:", response.data);

        Swal.fire({
          icon: "success",
          title: "SignUp Successful!",
          text: "You can perform additional actions after successful signup.",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });

        navigate("/sign-in");
      })
      .catch((error) => {
        console.error("SignUp Error:", error);

        Swal.fire({
          icon: "error",
          title: "SignUp Error",
          text: "There was an error during signup. Please try again.",
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      });
  };

  return (
    <>
      <div className="vh-100 " style={{ backgroundImage: `url(${signup})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', overflow: 'hidden' }}>
        {/* <div className="vh-100 vw-100 position-relative" style={{overflow:"hidden"}}> */}
        <div >
          <Navbar />
        </div>
        {/* <video autoPlay loop muted className="d-lg-block d-none"
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          minWidth: '100%',
          minHeight: '20%',
          width: 'auto',
          height: 'auto',
          zIndex: '0',
          transform: 'translate(-50%, -50%)',
          objectFit: 'contain'
        }}>
          <source src={signupvideo} type="video/mp4" style={{backgroundSize: 'contain', backgroundPosition: 'center' }} />
        </video> */}
        {/* background: 'rgba(255, 255, 255, 0.8)', */}
        <div className="d-flex justify-content-end pe-5 me-4 mt-5" style={{ marginRight: '6em' }}>
          <div className="signup-box" style={{ marginRight: '6em', borderRadius: '0px', }}>
            <form onSubmit={handleLogin}>
              <h2>Sign Up</h2>

              <div className="mb-3 mt-5 d-flex flex-column align-items-start">
                <div className="wrap-input-8 ">
                  <TextField
                    // className="input"
                    style={{ width: '100%', color: "secondary" }}
                    color="secondary"
                    type="name" required
                    name="name"
                    focused={formData.name.length === 0}
                    // helperText={!formData.name.length ? 'Name is required' : ''}
                    value={formData.name}
                    label="Name"
                    onChange={handleInputChange_name}
                  />
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
              </div>

              <div className="mb-3 d-flex flex-column align-items-start">
                <div className="wrap-input-8">
                  <TextField
                    // className="input"
                    style={{ width: '100%', color: "secondary" }}
                    color="secondary"
                    type="email" required
                    name="email"
                    focused={formData.email.length === 0}
                    // helperText={!formData.email.length ? 'Email is required' : ''}
                    value={formData.email}
                    label="Email"
                    onChange={handleInputChange}
                  />
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
              </div>

              <div className="mb-3 d-flex flex-column align-items-start">
                <div className="wrap-input-8">
                  <TextField
                    // className="input"
                    style={{ width: '100%' }}
                    type="password" required
                    name="password"
                    color="secondary"
                    focused={formData.password.length === 0}
                    // helperText={!formData.password.length ? 'Password is required' : ''}
                    value={formData.password}
                    label="Password"
                    onChange={handleInputChange}
                  />
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
                {errors.password && (
                  <div className="text-danger" style={{ fontSize: '0.875em' }}>{errors.password}</div>
                )}
              </div>

              <div className="d-grid" style={{ justifyItems: 'center' }}>
                <button type="submit" className="btn-shiny2" >
                  Create Account
                </button>
              </div>

              <p className="forgot-password text-center">
                Already registered <Link to="/sign-in">sign-in?</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
