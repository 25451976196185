import React, { Component } from "react";
import "../css/dash.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import BgVideo from "../../Assets/bgvideo.mp4";
export default class Home extends Component {
  render() {
    return (
      <>
      <div className="home-outer-div">
        <Navbar />
          <div className="content">
            <video loop muted autoPlay playsInline className="bgvideo">
              <source src={BgVideo} type="video/mp4"/>
            </video>
            <h1 className=" text-light">Welcome to Raas</h1>
            <p className="lead  text-light">Made by The Data Tech Labs</p>
            <Link className="btn start-here-btn text-light" to="/sign-up">
              Start Here!
            </Link>
          </div>
      </div>
       
      </>
    );
  }
}